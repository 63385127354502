import React, { useState } from 'react';
import { Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import { ProductSearchResult } from '@msdyn365-commerce/retail-proxy';
import { ICoreContext } from '@msdyn365-commerce/core';
import { getProductPageUrlSync } from '@msdyn365-commerce-modules/retail-actions';

const ProductPopover = ({ product, index, context, layout }: { product: ProductSearchResult; index: number; context: ICoreContext; layout: String }) => {
    const productUrl = product ? getProductPageUrlSync(product.Name ?? '', product.RecordId, context.actionContext, undefined) : '';
    const [popoverOpen, setPopoverOpen] = useState(false);
    const zIndex = index + 1;

    const togglePopover = (isVisible: boolean) => {
        setPopoverOpen(isVisible);
    };

    return (
        <>
            <span
                id={`popover-${index}`}
                onMouseEnter={() => togglePopover(false)}
                onMouseLeave={() => togglePopover(false)}
                style={{ position: 'relative', zIndex: zIndex }}
            >
                {layout === 'imagelist' ? <span className='msc-product-popover__firstColumn'><img className='msc-product-image' src={product.PrimaryImageUrl} alt={product.Name} /><span className='msc-product-popover__title'><a href={productUrl}>{product.Name}</a></span></span> :
                 <span className='msc-product-popover__title'><a href={productUrl}>{product.Name}</a></span>}
            </span>
            <Popover
                className='msc-product-popover'
                placement="bottom"
                isOpen={popoverOpen}
                target={`popover-${index}`}
                toggle={() => togglePopover(!popoverOpen)}
                style={{ zIndex: zIndex + 1 }}
            >
                <PopoverHeader></PopoverHeader>
                <PopoverBody
                    className='msc-product-popover__body'
                    onMouseEnter={() => togglePopover(false)}
                    onMouseLeave={() => togglePopover(false)}
                >
                    <img className='msc-product-popover__image' src={product.PrimaryImageUrl} alt={product.Name} />
                </PopoverBody>
            </Popover>
        </>
    );
};

export default ProductPopover;
